import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios'

const Button = (props) => {
  const ref = useRef(true);
  const baseURL = "https://www.mirakome.jp/mirakome/api/";
  const cat = props.cat;
  const num = props.num;
  const inc = props.inc;
  const dec = props.dec;
  const [active, setActive] = useState(false);
  const toggleClass = () => {
    setActive(prevState => !prevState);
  }
  useEffect( () => {
    if(ref.current){
      ref.current = false;
      return;
    }
    // 2回目以降
    if(active){
      axios.get(`${baseURL}inc/${cat}/${num}`)
      .then(response => {
        inc()
      });
    }
    else{
      axios.get(`${baseURL}dec/${cat}/${num}`)
      .then(response => {
        dec()
      });
    }
  }, [active, cat, num, inc, dec]);

  return (
    <div className="btn-box">
      <button onClick={toggleClass} className={active ? "active" : ""}>応援する<span></span></button>
    </div>
  )
}

export default Button;