import React, {useState, useEffect, useCallback} from 'react';
import axios from 'axios';
import NumberEasing from 'react-number-easing';
import Button from "./components/Button";

const App = () => {
  const baseURL = "https://www.mirakome.jp/mirakome/api/";
  const [total, setTotal] = useState(0);
  const [img_num, setImgNum] = useState(0);
  const incrementTotal = useCallback(() => {
    setTotal(prevState => prevState+1);
  },[setTotal]);
  const decrementTotal = useCallback(() => {
    setTotal(prevState => prevState-1);
  },[setTotal]);

  useEffect( () => {
    axios.get(`${baseURL}count`)
    .then(response => {
      setTotal(Number(response.data))
    });
  }, []);

  useEffect( () => {
    if(total      >= 3000000) setImgNum(16);
    else if(total >= 2500000) setImgNum(15);
    else if(total >= 2000000) setImgNum(14);
    else if(total >= 1750000) setImgNum(13);
    else if(total >= 1500000) setImgNum(12);
    else if(total >= 1250000) setImgNum(11);
    else if(total >= 1000000) setImgNum(10);
    else if(total >=  750000) setImgNum( 9);
    else if(total >=  500000) setImgNum( 8);
    else if(total >=  400000) setImgNum( 7);
    else if(total >=  300000) setImgNum( 6);
    else if(total >=  200000) setImgNum( 5);
    else if(total >=  100000) setImgNum( 4);
    else if(total >=   50000) setImgNum( 3);
    else if(total >=   30000) setImgNum( 2);
    else if(total >=   10000) setImgNum( 1);
  }, [total]);
  
  return (
    <>
    <section className="digital">
      <div className="title">
        <div className="image"></div>
        <p>DIGITAL</p>
        <h2>デジタル分野の政策</h2>
        <div className="lead">誰も取り残さないデジタル社会へ</div>
      </div>
      <div className="question">
        <div className="subtitle">健康×デジタル</div>
        <ul>
          <li><span>オンライン診療を気軽に受けられる</span></li>
        </ul>
        <Button cat="a" num="1" inc={incrementTotal} dec={decrementTotal} />
      </div>
      <div className="question">
        <div className="subtitle">移動×デジタル</div>
        <ul>
          <li><span>スマホでトイレやエレベーターなどの場所が確認できる</span></li>
        </ul>
        <Button cat="a" num="2" inc={incrementTotal} dec={decrementTotal} />
      </div>
      <div className="question">
        <div className="subtitle">働く×デジタル</div>
        <ul>
          <li><span>デジタル分野の職業訓練を受けられる</span></li>
        </ul>
        <Button cat="a" num="3" inc={incrementTotal} dec={decrementTotal} />
      </div>
      <div className="question">
        <div className="subtitle">防災×デジタル</div>
        <ul>
          <li><span>今いる場所の危険度や近くの避難所が確認できる</span></li>
        </ul>
        <Button cat="a" num="4" inc={incrementTotal} dec={decrementTotal} />
      </div>
      <div className="question">
        <div className="subtitle">教育×デジタル</div>
        <ul>
          <li><span>子どもたちが、もっとデジタルを活用した授業を受けられるようになる</span></li>
        </ul>
        <Button cat="a" num="5" inc={incrementTotal} dec={decrementTotal} />
      </div>
      <div className="question">
        <div className="subtitle">情報格差解消×デジタル</div>
        <ul>
          <li><span>無料でスマホの使い方を教えてもらえる</span></li>
        </ul>
        <Button cat="a" num="6" inc={incrementTotal} dec={decrementTotal} />
      </div>
      <div className="fig fig01"></div>
    </section>
    <section className="ecology">
      <div className="title">
        <div className="image"></div>
        <p>ECOLOGY</p>
        <h2>エコ分野の政策</h2>
        <div className="lead">日本を環境大国へ</div>
      </div>
      <div className="question">
        <div className="subtitle">エコ（食品ロス削減）</div>
        <ul>
          <li><span>食品ロスを減らしてＣO2を削減する</span></li>
        </ul>
        <Button cat="b" num="1" inc={incrementTotal} dec={decrementTotal} />
        <ul>
          <li><span>まだ食べられるものは寄付できるフードドライブを各地で行う</span></li>
        </ul>
        <Button cat="b" num="2" inc={incrementTotal} dec={decrementTotal} />
        <ul>
          <li><span>余った食材を最新技術により新たな製品にする
            <span>例)売れ残りの野菜やパンからビールなどに</span></span></li>
        </ul>
        <Button cat="b" num="3" inc={incrementTotal} dec={decrementTotal} />
      </div>
      <div className="question">
        <div className="subtitle">エコ（地球環境）</div>
        <ul>
          <li><span>家庭や職場で、すぐに取り組める省エネ情報を手に入れられる</span></li>
        </ul>
        <Button cat="b" num="4" inc={incrementTotal} dec={decrementTotal} />
        <ul>
          <li><span>スマホで節電チェックができる</span></li>
        </ul>
        <Button cat="b" num="5" inc={incrementTotal} dec={decrementTotal} />
        <ul>
          <li><span>環境に配慮した行動にポイントがもらえる</span></li>
        </ul>
        <Button cat="b" num="6" inc={incrementTotal} dec={decrementTotal} />
      </div>
    </section>
      <div className="flower-box">
        <div className="img"><img src={`${process.env.PUBLIC_URL}/img/img_sakura-c.svg`} alt="" /></div>
        <h2>「あなた」の声で咲く<br />東京ビジョンアート</h2>
        <p className="lead">いただいた応援の声の数に応じて<br />
          下の木に花が咲いていきます<br />
          未来への希望の花を<br />
          満開に咲かせましょう</p>
        <div className="counter">
          <h3>現在の応援数</h3>
          <p className="current">
            <NumberEasing
              value={total}
              speed={3000}
              decimals={0}
              ease="quintInOut" />
          </p>
        </div>
        <div className="flower-bed">
          <img src={`${process.env.PUBLIC_URL}/img/${img_num}.jpg`} alt="" />
        </div>
      </div>
    </>
  );
}

export default App;
